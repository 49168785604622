import axios, { type AxiosRequestConfig } from 'axios'
import router from '../router'
import { getI18n, t } from '@/plugins/i18n'
import { useAuthStore } from '../store/auth'
import { swal } from '@/utils/sweetalert'
import { client } from 'laravel-precognition-vue'
import jsonBigInt from 'json-bigint'
const JSONbig = jsonBigInt({ useNativeBigInt: true })

window.axios = axios

axios.defaults.baseURL = '/api'
axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true

axios.defaults.headers.common['Accept'] = 'application/json'

axios.interceptors.response.use(undefined, async (error) => {
  error.validationResult = () => {
    if (
      !error.response ||
      !error.response.data ||
      error.response.status !== 422
    )
      return false

    swal(
      error.response.data.message,
      t('general.errors.invalid-input-fields'),
      'error'
    )
    return true
  }

  error.includes = (string, key = null) => {
    if (!error.response) return false
    if (
      key &&
      error.response.data &&
      error.response.data.errors &&
      error.response.data.errors[key] &&
      error.response.data.errors[key].includes(string)
    ) {
      return true
    }
    if (!key) {
      for (const err in error.response.data.errors) {
        if (error.response.data.errors[err].includes(string)) return true
      }
    }

    if (error.response.data.error && error.response.data.error.includes(string))
      return true
    if (
      error.response.data.message &&
      error.response.data.message.includes(string)
    )
      return true
    if (
      error.response.data &&
      typeof error.response.data !== 'object' &&
      error.response.data.includes(string)
    )
      return true
    return false
  }

  error.status = (status) => error.response && error.response.status === status

  if (
    error.includes('token_not_provided') ||
    error.status(401) ||
    error.status(429)
  ) {
    useAuthStore().user = null
  } else if (
    error.includes('token_invalid') ||
    error.includes('token_expired')
  ) {
    useAuthStore().user = null
    localStorage.removeItem('default_auth_token')
    router.push({
      name: 'auth.login',
      query: { redirect: router.currentRoute.value.path },
    })
  } else if (
    error.status(403) &&
    error.includes('Your account has been deactivated')
  ) {
    swal(
      'Benutzer deaktiviert',
      'Dein Benutzer wurde deaktiviert. Bitte kontaktiere Steffan Brunner um deinen Benutzer wieder zu aktivieren.',
      'error'
    ).then(() => {
      useAuthStore().user = null
    })
  } else if (error.status(403)) {
    swal(
      'Nicht berechtigt',
      'Du bist für diese Aktion nicht berechtigt',
      'error'
    )
  }

  return Promise.reject(error)
})

axios.interceptors.request.use(function (config) {
  config.headers['accept-language'] = getI18n().value?.global.locale ?? 'de'

  return config
})

axios.interceptors.request.use((request) => {
  // transform all json request data to string with bigint support
  if (['post', 'put', 'patch'].includes(request.method?.toLowerCase() ?? '')) {
    if (
      request.data &&
      typeof request.data === 'object' &&
      request.data instanceof FormData === false
    ) {
      try {
        request.data = JSONbig.stringify(request.data)
        request.headers['Content-Type'] = 'application/json'
      } catch (e) {}
    }
  }

  // transform all responses to JSON with bigint support
  request.transformResponse = [
    (data) => {
      try {
        return JSONbig.parse(data)
      } catch (e) {
        return data
      }
    },
  ]
  return request
})

// Request helpers ($get, $post, ...)
// see nuxt axios module
for (const method of [
  'request',
  'delete',
  'get',
  'head',
  'options',
  'post',
  'put',
  'patch',
]) {
  axios[`$${method}`] = function (...args) {
    // eslint-disable-next-line prefer-spread
    return axios[method].apply(axios, args).then((res) => res && res.data)
  }
}

type CustomAxios = typeof axios & {
  $get: <T = any, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>
  ) => Promise<T>
  $post: <T = any, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ) => Promise<T>
  $put: <T = any, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ) => Promise<T>
  $patch: <T = any, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ) => Promise<T>
  $delete: <T = any, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>
  ) => Promise<T>
}

client.use(axios)

export default axios as CustomAxios
