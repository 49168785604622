export function getCookieByName(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

// function setCookie(name, value, days) {
//   let expires = ''
//   if (days) {
//     const date = new Date()
//     date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
//     expires = `; expires=${date.toUTCString()}`
//   }
//   document.cookie = `${name}=${value || ''}${expires}; path=/`
// }

const travelTo = getCookieByName('travelTo')

// Date.now = () => new Date('2024-01-01').getTime()

if (travelTo) {
  Date = class extends Date {
    constructor(...args) {
      if (args.length === 0) {
        super(travelTo)
      } else {
        super(...args)
      }
    }
  }

  Date.now = () => {
    return new Date(travelTo).getTime()
  }
}
